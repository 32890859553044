<!-- <span>
    {{data | json}}
</span> -->
<div class="card p-2 bg-dark">
    <div class="card-body">
        <h6 class="card-title">
        {{data.title}}
        </h6>
        <div class="d-flex overflow-auto" *ngIf="data.bandcamp">
            <iframe style="border: 0; width: 350px; height: 757px;" 
            [src]="data.bandcamp.src | safe" seamless>
                <a href="{{data.bandcamp.link}}">
                    {{data.bandcamp.text}}
                </a>
            </iframe>
            
            <div class="m-2 card bg-dark" style="border: 0; width: 550px; height: 757px;">
                <div class="card-title text-white">
                    <h5>{{data.title}}</h5>
                </div>
                <div class="card-body text-white">
                    {{data.blurb}}
                </div>
            </div>
        </div>
       
    </div>
</div>